<template>
	<v-sheet class="member" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="4" class="py-1">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="8" class="py-0 my-auto text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-btn
						v-if="getPermission('user::create')"
						depressed
						:disabled="pageLoading"
						tile
						color="blue darken-4"
						class="text-white"
						:to="{
							name: 'user-create',
							query: { tab: 'overview' },
						}"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<v-btn
						v-if="false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>
					<v-btn
						v-if="false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="advanceSearch = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn>
					<v-menu
						v-if="false"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								{{ listingAction }}
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						v-model="statusOne"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<template v-if="statusOne">
							<Draggable
								tag="ul"
								v-model="draggableThead"
								class="draggable-group"
								handle=".draggable-drag-icon"
								v-on:change="updateTable('user')"
							>
								<template v-for="cols in draggableThead">
									<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
										<v-checkbox
											dense
											v-model="draggableTheadShow"
											v-bind:value="cols.key"
											:label="cols.name"
											:disabled="cols.fixed || pageLoading"
											color="blue"
											hide-details
											class="mt-0 mb-0"
											v-on:change="updateTableVisiblity('user')"
										></v-checkbox>
										<v-icon
											v-if="!pageLoading && !cols.fixed"
											class="draggable-action draggable-drag-icon"
											right
											color="blue"
											>mdi-drag</v-icon
										>
										<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
									</li>
								</template>
							</Draggable>
						</template>
					</v-menu>
				</template>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="User #, Name, Company Name, Display Name and Email Address"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>

		<Table
			:page-loading="pageLoading"
			type="user"
			delete-endpoint="member/"
			detail-route="user-detail"
			update-route="user-update"
			v-on:reload:content="filterRows"
			delete-note="All transactions of this user will also be deleted."
		></Table>
		<!-- <ExportDialog
			endpoint="user/export?type=user&current=1"
			title="Users"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="user/export?type=user"
			title="Users"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog> -->
		<ImportDialog
			endpoint="user/import?type=user"
			title="Users"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Users"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";

export default {
	name: "user-listing",
	title: "Listing User",
	mixins: [ListingMixin],
	data() {
		return {
			pageTitle: "Users",
			pageBreadcrumbs: [{ text: "Usre", disabled: true }],
			endpoint: "user",
			defaultFilter: {},
		};
	},
};
</script>
